



























































import { Component, Vue  } from "vue-property-decorator";
import '@/assets/css/user/main.css';
import '@/assets/css/login/util.css';
import ErrorText from "@/views/user/componets/ErrorText.vue";

@Component({
  components:{ ErrorText }
})

export default class AuthFindPW extends Vue {

  $refs!: { [key:string]: any }
  pending: boolean;
  mailSend : boolean;

  //user info
  token : string;

  // /---------------
  userPassword: string;
  userConfirmPassword: string;
  isAblePw: boolean;
  okPw: boolean;
  okConfirmPw: boolean;
  isEqualsPw: boolean;

  constructor() {
    super();
    this.pending = false;
    this.mailSend = false;
    this.userPassword = '';
    this.userConfirmPassword = '';
    this.token = this.$route.params.token;

    this.isAblePw = true;
    this.isEqualsPw = true;
    this.okConfirmPw = false;
    this.okPw = false;
  }

  created() {
    const isLogin = this.$store.getters.isLogin;
    if (isLogin) this.$router.push('/project/list');
    this.$store.commit('setToken',this.token);
  }

  async pwCheck() {
    if (this.userPassword) {
      const sendData = {
        userPassword: this.userPassword
      }
      const {data} = await Vue.axios({
        url: '/user/pwCheck',
        method: 'POST',
        data: sendData
      })
      this.isAblePw = data.result;
      this.okPw = data.result;
      if(this.okPw && this.userConfirmPassword) await this.pwEqualsCheck();
    }else{
      this.isAblePw = false;
      this.okPw = false;
    }
  }

  async pwEqualsCheck() {

    if (this.userConfirmPassword === this.userPassword) {
      const sendData = {
        userPassword: this.userPassword,
        userConfirmPassword: this.userConfirmPassword
      }
      const {data} = await Vue.axios({
        url: '/user/pwEqualCheck',
        method: 'POST',
        data: sendData
      })

      this.okConfirmPw = data.result;
      this.isEqualsPw = data.result;
    }else{
      this.okConfirmPw = false;
      this.isEqualsPw = false;
    }

  }

  async changePassword(){
    if(this.okConfirmPw && this.okPw){
      const sendData = {
        userPassword : this.userPassword,
        userConfirmPassword : this.userConfirmPassword
      }
      const {data} = await Vue.axios({
        url: '/user/passwordChange',
        method: 'POST',
        data: sendData
      });
      if(data.result){
        Vue.$toast.open({
          message: '비밀번호가 성공적으로 변경되었습니다.',
          type: 'success',
          duration: 7000
        });
        location.replace('/user/login');
      }
    }else{
      await this.toastErrorMessage('비밀번호를 확인해주세요');
    }

  }


  async toastErrorMessage(message:string){
    Vue.$toast.open({
      message: message,
      type: 'error',
      duration: 5000
    });
  }



  mounted(){}

}
